import MedicalSpeciality from '@/statics/medicalSpeciality';
import Range from '@/statics/range';
import Trolley from '@/statics/trolley';
import { Feature, GIProcedureArea, LowerGILength, RespiratoryType } from '@/statics/scopeFilter';
import { LocaleCode } from '@/statics/locale';
import { ProcedureType, ProcedureTypeSelectOptions } from '@/statics/procedureType';

export default {
  meta: {
    /* same as meta infos in index_nl.html */
    title: 'Endoscopietorensysteem — Configuratie in 5 stappen',
    description:
      'Selecteer de focus van uw procedures, optimaliseer conform uw behoeften en ontvang per e-mail uw individuele endo-torenconfiguratie.'
  },
  locale: {
    [LocaleCode.DE]: 'Duits',
    [LocaleCode.EN]: 'Engels (US)',
    [LocaleCode.EN_GB]: 'Engels (UK)',
    [LocaleCode.ES]: 'Spaans',
    [LocaleCode.IT]: 'Italiaans',
    [LocaleCode.NL]: 'Nederlands',
    [LocaleCode.RU]: 'Russisch'
  },
  step: 'Stap',
  quantity: 'Aantal',
  shortPcsCounted: '{n} st.',
  progressBarTitles: {
    gettingStarted: '@:gettingStarted.title',
    procedures: '@:procedures.title',
    optimize: '@:optimize.title',
    accessories: '@:accessories.title',
    request: '@:request.title'
  },
  loadingConfiguration: 'Configuratie laden...',
  gettingStarted: {
    title: 'Aan de slag',
    startScreenHeadline: 'Configureer in enkele stappen uw nieuwe endoscopietoren.',
    medicalSpecialityHeadline: 'Kies uw medisch specialisme',
    medicalSpeciality: {
      [MedicalSpeciality.GASTROENTEROLOGY]: 'Gastro-enterologie',
      [MedicalSpeciality.PULMONOLOGY]: 'Pulmonologie'
    },
    trolleyProcedureTypesHeadline: 'Selecteer de focus van uw procedures',
    procedureTypeSelectOptions: {
      [ProcedureTypeSelectOptions.ADVANCED]: 'Geavanceerde procedures en opties',
      [ProcedureTypeSelectOptions.GENERAL]: 'Algemene procedures'
    }
  },
  procedures: {
    title: 'Procedures',
    headline: 'Kies uw procedures en de focus van uw voorkeursopstelling.',
    procedureRequest: 'Voor welke procedures bent u van plan te investeren in nieuwe apparatuur?',
    procedureTypes: {
      [ProcedureType.DIAGNOSTIC]: 'Algemene procedures',
      [ProcedureType.THERAPEUTIC]: 'Geavanceerde procedures en opties'
    },
    errorNoProcedureSelected: 'Selecteer eerst een van de onderstaande procedures.',
    selectAll: 'Alles selecteren',
    resetSelection: 'Selectie resetten',
    hasRequirementsInfo: 'Selecteer eerst minstens één van de procedures:',
    isRequirementInfo: 'Verwijder eerst deze geselecteerde procedures:',
    rangeRequest: 'Selecteer de focus van uw voorkeursopstelling',
    range: {
      [Range.BASIC]: 'Beste prijs',
      [Range.MID_RANGE]: 'Prijs en innovatie',
      [Range.INNOVATION]: 'Beste prestaties'
    }
  },
  optimize: {
    title: 'Optimaliseren',
    trolleys: {
      [Trolley.MAIN]: 'Endo-toren',
      [Trolley.GHOST]: 'Aanvullende items',
      [Trolley.ESG]: 'HF-generators'
    },
    trolleyTitles: {
      [Trolley.MAIN]: 'Uw endoscopietoren',
      [Trolley.GHOST]: `@:optimize.trolleys.${Trolley.GHOST}`,
      [Trolley.ESG]: `@:optimize.trolleys.${Trolley.ESG}`
    },
    empty: 'blanco',
    addComponent: 'Componenten toevoegen',
    scopeGuide: 'ScopeGuide',
    zones: {
      M0: 'Monitors',
      M1: 'Kunstmatige intelligentie',
      M2: 'Videoprocessors',
      M3: 'Ultrageluidprocessor',
      M4: 'Pompen',
      M5: 'CO²-insufflator',
      M6: 'HF generators',
      M7: 'Endoscopen'
    },
    selectedCounted: '{count} geselecteerd',
    viewESG: 'HF-generators',
    ghostDescription:
      'Als niet alle geselecteerde items fysiek op uw endoscopietoren passen, worden aanvullende items in deze sectie weergegeven (alleen bedoeld ter visualisatie).',
    esgDescription:
      'De ESG-300 wordt geleverd met de TC-E300 trolley (en optionele argonplasma-unit).',
    supportHintMedicalSpeciality: 'alleen {supported}'
  },
  changeArticle: {
    subline: 'Uw individuele configuratie',
    headline: 'Producten wijzigen',
    showDetails: 'details tonen',
    scopesSelectedCounted: '{count} scope geselecteerd |{count} scopen geselecteerd',
    currentlySelected: 'Momenteel geselecteerd',
    productCode: 'Productcode',
    hasRequirementsInfo: 'Selecteer eerst een van deze producten:',
    isRequirementInfo: 'Verwijder eerst deze geselecteerde producten:',
    technicalSpecifications: 'Technische Specificaties',
    noArticlesAvailable:
      'Er zijn geen artikelen compatibel met de momenteel geselecteerde videoprocessor.',
    noScopesAvailable:
      'Geen scopes van deze selectie die compatibel zijn met de huidige geselecteerde videoprocessor.',
    scopeGuideSubline: 'Systeem voor 3D-beeldnavigatie',
    scopeGuideIntro: 'Werking van ons systeem voor 3D-beeldnavigatie ScopeGuide',
    scopeGuideSummary:
      'ScopeGuide biedt een real-time 3D-beeld van de positie en configuratie van de endoscoop in de colon.',
    scopeGuideDescription:
      'Er zijn elektromagnetische spoelen ingebouwd langs de lengte van het speciale invoergedeelte van de scoop en deze genereren een gepulseerd magnetisch veld met lage intensiteit dat wordt opgepikt door de ontvangerschotel. <br /> Aan de hand van de magnetische pulsen wordt de precieze positie en oriëntatie van het invoergedeelte berekend voor een driedimensionaal beeld op de monitor.',
    scopeGuideIncludedItems: 'Bevat de volgende items:',
    notVisualized: 'Niet gevisualiseerd in trolleyconfiguratie.'
  },
  scopeFilter: {
    area: {
      label: 'Gebied',
      items: {
        [GIProcedureArea.UPPER_GI]: 'Bovenste tractus digestivus',
        [GIProcedureArea.LOWER_GI]: 'Onderste tractus digestivus',
        [GIProcedureArea.ERCP]: 'ERCP',
        [GIProcedureArea.ENTEROSCOPY]: 'Enteroscopie',
        [GIProcedureArea.ULTRASOUND]: 'Ultrageluid'
      }
    },
    length: {
      label: 'Scooplengte',
      items: {
        [LowerGILength.INTERMEDIATE]: 'Gemiddeld',
        [LowerGILength.LONG]: 'Lang'
      }
    },
    respiratoryType: {
      label: 'Scooptype',
      items: {
        [RespiratoryType.BRONCHOSCOPE]: 'Bronchoscoop',
        [RespiratoryType.MINIPROBE]: 'Minisonde',
        [RespiratoryType.PLEURASCOPE]: 'Pleurascoop',
        [RespiratoryType.ULTRASOUND]: 'Ultrageluid'
      }
    },
    features: {
      label: 'Kenmerken',
      items: {
        [Feature.WATER_JET]: 'Waterstraal',
        [Feature.HQ]: 'HF-compatibel',
        [Feature.TRANSNASAL]: 'Transnasaal',
        [Feature.PCF]: 'PCF'
      }
    },
    series: {
      label: 'Scoopserie'
    }
  },
  accessories: {
    title: 'Accessoires',
    headline: 'Voor een volledig uitgerust endoscopiesysteem raden we de volgende accessoires aan'
  },
  request: {
    title: 'Aanvraag verzenden',
    subline: 'Uw selectie',
    headline: 'Wij nemen contact met u op met een individueel aanbod',
    areaTrolley: 'Endoscopietoren',
    areaAccessories: 'Accessoires'
  },
  success: {
    title: 'Aanvraag verzonden',
    headline: 'Hartelijk dank.',
    subline:
      'Uw aanvraag voor de configuratie van uw endoscopietoren is verzonden. Het kan tot vijf minuten duren voordat u uw e-mail ontvangt.<br /> We nemen spoedig contact met u op.',
    secondSubline: 'Ontdek meer over EVIS X1',
    buttonNew: 'Start een nieuwe configuratie',
    buttonDetail: 'Lees meer',
    detailAssetPath: '/assets/successScreen_bottom_en.png',
    detailLink:
      'https://www.olympus.nl/medical/nl/Producten-oplossingen/Producten/Product/EVIS-X1.html'
  },
  contact: {
    subline: 'Contact',
    headline: 'Ondersteuning ontvangen',
    info: 'Hulp nodig bij de configuratie van uw endoscopietoren? Geef ons een seintje en we nemen snel contact met u op!',
    success:
      'Uw aanvraag voor de configuratie van uw endoscopietoren is verzonden.<br />We nemen spoedig contact met u op.',
    customerQuestionHeadline: 'Aanvraag',
    backToConfiguration: 'Terug naar configuratieCo'
  },
  saveConfiguration: {
    title: 'Ga later verder',
    subtitle: 'Bewaar voor later',
    subline: 'Voer uw e-mailadres in, en wij sturen u een link om uw configuratie te hervatten.',
    sendLink: 'Stuur me de link',
    copyLinkToClipboard: 'Kopieer deze link naar het klembord',
    emailHeadline: 'Wat is uw e-mail?',
    writeClipboardSuccess: 'Gekopieerd naar klembord',
    writeClipboardFailed: 'Kopiëren naar klembord mislukt',
    successHeadline: 'Configuratie opgeslagen',
    successSubline:
      'Uw configuratie is succesvol opgeslagen.<br /> Ga op elk moment verder via de onderstaande link of via de e-mail die wij u binnenkort zullen sturen.'
  },
  footerUrlsLabel: {
    imprint: 'Afdruk',
    termsOfUse: 'Voorwaarden',
    privacyNotice: 'Privacy',
    cookies: 'Cookies',
    cookiesSettings: 'Cookie-instellingen',
    socialMedia: 'Social Media'
  },
  contactForm: {
    country: 'Land',
    countryHeadline: 'Selecteer uw land',
    customerName: 'Voor- en achternaam',
    customerNameHeadline: 'Wat is uw naam?',
    email: 'E-mailadres',
    emailHeadline: 'Wat is uw e-mailadres?',
    customerPhoneNumber: 'Telefoonnummer',
    customerPhoneNumberHeadline: 'Wat is uw telefoonnummer?',
    customerCompany: 'Uw ziekenhuis/organisatie',
    customerCompanyHeadline: 'Voor welk ziekenhuis/welke organisatie werkt u?',
    customerQuestion: 'Uw bericht',
    customerQuestionHeadline: 'Wilt u meer informatie met ons delen?',
    privacy:
      'Olympus respecteert uw privacy. Raadpleeg onze {link} voor informatie over hoe we uw persoonsgegevens verwerken om uw vraag te beantwoorden.',
    checkboxRequired: '*Verplichte velden'
  },
  actions: {
    back: 'Terug',
    confirm: 'Bevestigen',
    continue: 'Doorgaan',
    no: 'Nee',
    save: 'Save',
    select: 'Selecteer',
    selectNothing: 'Selecteer niets',
    selected: 'Geselecteerd',
    selectedNothing: 'Niets geselecteerd',
    sendRequest: 'Aanvraag verzenden',
    submit: 'Verzenden',
    yes: 'Ja'
  },
  error: {
    alert: 'Er is iets misgegaan. Probeer het later nog eens.',
    title: 'Er is iets misgegaan',
    subline: 'Hartelijk dank voor uw belangstelling in Olympus-apparatuur!',
    info: 'Helaas is er een fout opgetreden. Wacht enkele seconden en probeer uw laatste actie opnieuw of vernieuw deze pagina.',
    contact: 'Neem contact op met onze {0}.',
    customerService: 'klantenservice',
    refresh: 'Pagina verversen',
    retry: 'Opnieuw proberen'
  },
  formData: {
    customerName: 'Naam',
    customerCompany: '@:contactForm.customerCompany',
    country: 'Land',
    email: 'E-mailadres',
    customerPhoneNumber: 'Telefoonnummer',
    customerQuestion: 'Bericht',
    optional: 'optioneel'
  },
  countryOptions: [
    { label: 'Albanië', value: 'AL' },
    { label: 'Algerije', value: 'DZ' },
    { label: 'Angola', value: 'AO' },
    { label: 'Armenië', value: 'AM' },
    { label: 'Oostenrijk', value: 'AT' },
    { label: 'Azerbeidzjan', value: 'AZ' },
    { label: 'Bahrein', value: 'BH' },
    { label: 'Wit-Rusland', value: 'BY' },
    { label: 'België', value: 'BE' },
    { label: 'Bosnië en Herzegovina', value: 'BA' },
    { label: 'Botswana', value: 'BW' },
    { label: 'Bulgarije', value: 'BG' },
    { label: 'Kameroen', value: 'CM' },
    { label: 'Kroatië', value: 'HR' },
    { label: 'Cyprus', value: 'CY' },
    { label: 'Tsjechische Republiek', value: 'CZ' },
    { label: 'Denemarken', value: 'DK' },
    { label: 'Djibouti', value: 'DJ' },
    { label: 'Egypte', value: 'EG' },
    { label: 'Estland', value: 'EE' },
    { label: 'Ethiopië', value: 'ET' },
    { label: 'Finland', value: 'FI' },
    { label: 'Frankrijk', value: 'FR' },
    { label: 'Georgië', value: 'GE' },
    { label: 'Duitsland', value: 'DE' },
    { label: 'Ghana', value: 'GH' },
    { label: 'Griekenland', value: 'GR' },
    { label: 'Hongarije', value: 'HU' },
    { label: 'IJsland', value: 'IS' },
    { label: 'Iran', value: 'IR' },
    { label: 'Irak', value: 'IQ' },
    { label: 'Israël', value: 'IL' },
    { label: 'Italië', value: 'IT' },
    { label: 'Ivoorkust', value: 'CI' },
    { label: 'Jordanië', value: 'JO' },
    { label: 'Kazachstan', value: 'KZ' },
    { label: 'Kenia', value: 'KE' },
    { label: 'Koeweit', value: 'KW' },
    { label: 'Kirgizië', value: 'KG' },
    { label: 'Letland', value: 'LV' },
    { label: 'Libanon', value: 'LB' },
    { label: 'Lesotho', value: 'LS' },
    { label: 'Libië', value: 'LY' },
    { label: 'Litouwen', value: 'LT' },
    { label: 'Macedonië', value: 'MK' },
    { label: 'Malawi', value: 'MW' },
    { label: 'Mauritanië', value: 'MR' },
    { label: 'Mauritius', value: 'MU' },
    { label: 'Montenegro', value: 'ME' },
    { label: 'Marokko', value: 'MA' },
    { label: 'Mozambique', value: 'MZ' },
    { label: 'Namibië', value: 'NA' },
    { label: 'Nederland', value: 'NL' },
    { label: 'Nigeria', value: 'NG' },
    { label: 'Noorwegen', value: 'NO' },
    { label: 'Oman', value: 'OM' },
    { label: 'Palestina', value: 'PS' },
    { label: 'Polen', value: 'PL' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Katar', value: 'QA' },
    { label: 'Republiek Congo', value: 'CG' },
    { label: 'Roemenië', value: 'RO' },
    { label: 'Rusland', value: 'RU' },
    { label: 'Rwanda', value: 'RW' },
    { label: 'Saudi Arabie', value: 'SA' },
    { label: 'Senegal', value: 'SN' },
    { label: 'Servië & Montenegro', value: 'RS' },
    { label: 'Seychellen', value: 'SC' },
    { label: 'Slowakije', value: 'SK' },
    { label: 'Slovenië', value: 'SI' },
    { label: 'Somalië', value: 'SO' },
    { label: 'Republiek Zuid-Afrika', value: 'ZA' },
    { label: 'Zuid-Soedan', value: 'SS' },
    { label: 'Spanje', value: 'ES' },
    { label: 'Soedan', value: 'SD' },
    { label: 'Swaziland', value: 'SZ' },
    { label: 'Zweden', value: 'SE' },
    { label: 'Zwitserland', value: 'CH' },
    { label: 'Syrië', value: 'SY' },
    { label: 'Tanzania', value: 'TZ' },
    { label: 'Togo', value: 'TG' },
    { label: 'Tunesië', value: 'TN' },
    { label: 'Turkije', value: 'TC' },
    { label: 'Verenigd Koninkrijk & Ierland', value: 'GB' },
    { label: 'Oeganda', value: 'UG' },
    { label: 'Oekraïne', value: 'UA' },
    { label: 'Verenigde Arabische Emiraten', value: 'AE' },
    { label: 'Oezbekistan', value: 'UZ' },
    { label: 'Zambia', value: 'ZM' },
    { label: 'Zimbabwe', value: 'ZW' }
  ]
};
